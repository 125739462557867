$primary-color: lighten(#044792, 30%);

:root {
    --color-primary: #3692f9;
    --color-alternate-row: #f7f8f9;
}
// $control-width-xs: 180px !default;
// $control-width-sm: 320px !default;
// $control-width-md: 640px !default;
// $control-width-lg: 960px !default;
// $control-width-xl: 1280px !default;
// spectre.css core
@import '~spectre.css/src/variables';
@import '~spectre.css/src/mixins';
@import '~spectre.css/src/normalize';
@import '~spectre.css/src/base';
@import '~spectre.css/src/utilities';
@import '~spectre.css/src/animations';
@import '~spectre.css/src/media';
// spectre.css modules
@import '~spectre.css/src/typography';
@import '~spectre.css/src/layout';
@import '~spectre.css/src/buttons';
@import '~spectre.css/src/tables';
@import '~spectre.css/src/navbar';
@import '~spectre.css/src/forms';
@import '~spectre.css/src/dropdowns';
@import '~spectre.css/src/modals';
@import '~spectre.css/src/menus';
@import '~spectre.css/src/pagination';
@import '~spectre.css/src/empty';
@import '~spectre.css/src/tooltips';
@import '~spectre.css/src/labels';
@import '~spectre.css/src/popovers';
@import '~spectre.css/src/cards';
@import '~spectre.css/src/toasts';
@import '~spectre.css/src/badges';
// spectre.css icons
@import '~spectre.css/src/spectre-icons.scss';
// react-infinite-tree
@import '~react-infinite-tree/dist/react-infinite-tree';
// react-accesible-accordion
// @import '~react-accessible-accordion/dist/fancy-example';
// rc-slider
@import '~rc-slider/assets/index';
// react-toastify
@import '~react-toastify/dist/ReactToastify';
// rc-tooltip
@import '~rc-tooltip/assets/bootstrap';
// DropdownList
@import 'components/utils/DropdownList.scss';
// react-menu
@import '~@szhsin/react-menu/dist/index.css';

html, body {
    font-family: 'Roboto', sans-serif !important;
}

h2 {
    margin-bottom: 0.3rem;
}

.szh-menu {
    padding: 0 0 0.2rem;
}

.szh-menu-button {
    // height: 50px;
    // padding-top: 10px;
    margin-right: 4px;
    border: 0 none;
    box-shadow: none;
}

.btn.szh-menu-button {
    @apply lg:bg-[#3692f9] lg:text-white;
}

.szh-menu__submenu {
    margin-top: 0;
}

.szh-menu__item {
    margin-top: 0;
    padding: 0 0;
    position: relative;
    text-decoration: none;
    font-size: 16px;
    &:first-child {
        margin-top: 0.2rem;
    }
    > a {
        border-radius: 0.1rem;
        color: inherit;
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.2rem 0.8rem;
        text-decoration: none;
        &.active {
            background: #f0f7ff;
            color: var(--color-primary);
        }
    }
}

.szh-menu__item--hover {
    background: #f0f7ff;
    color: var(--color-primary);
}

.--tabulisk-wrapper {
    // overflow: hidden;
    display: block;
    // margin: 0 -0.4rem;
    flex-grow: 1;
}

.--tabulisk-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.table.--tabulisk-table {
    display: table;
    height: auto;
    width: 100%;
}

.--tabulisk-thead {
    overflow-x: hidden;
    flex-shrink: 0;
    border-bottom: 0.05rem solid #dadee4;
    [role=columnheader] {
        padding: 0.1rem 0.4rem;
        color: var(--color-primary);
        font-size: 0.9em;
        background-color: #dadee4;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.table .--tabulisk-thead th {
    height: 33px;
    span {
        position: relative;
        top: 4px;
    }
}

.--tabulisk-table-flex div[role="columnheader"]:last-child {
    margin-right: 17px;
}

.--tabulisk-tbody {
    overflow-y: hidden;
    height: 100%;
    overflow-x: auto;
    // [role=row]:nth-child(2n+1) {
    //     background-color: #f7f8f9 !important;
    // }
    [role=cell] {
        border-bottom: 0.05rem solid #dadee4;
        padding: 0.1rem 0.4rem;
    }
}

.--tabulisk-paginator-wrapper {
    // display: none;
    display: flex;
    margin-top: 12px;
}

.--tabulisk-paginator {
    display: flex;
    flex-grow: 1;
}

.--tabulisk-paginator-item {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    color: inherit;
    padding: 0.25rem 0.4rem;
    &:hover {
        text-decoration: none;
        color: var(--color-primary) !important;
    }
}

.--tabulisk-paginator-item:first-child,
.--tabulisk-paginator-item:last-child {
    padding: 0.25rem;
}

.--tabulisk-paginator-item.--tabulisk-paginator-item-active {
    border: 1px solid black;
    color: black;
    font-weight: bold;
}

.--tabulisk-paginator-item.--tabulisk-paginator-item-disabled {
    color: grey !important;
    cursor: auto;
}

.table .--tabulisk-no-rows-container {
    position: absolute;
    display: table;
    width: 100%;
}

.table-dense td.--tabulisk-no-rows-container {
    padding: 0;
}

.tooltip::after {
    max-width: 750px;
}

.tooltip.tooltip-danger::after {
    background-color: rgba($warning-color, 0.9);
}

.tooltip.tooltip-bottom.tooltip-bottom-low::after {
    top: 108%;
}

.tooltip-wide.tooltip::after {
    white-space: initial;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
}

.form-label.label-attributes {
    font-size: 0.75rem;
    overflow: hidden;
    height: 1.3rem;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.2rem 0;
}

.empty.empty-short {
    padding: 1rem;
}

.content-area {
    margin-top: 55px; // display: flex;
}

.sidebar-area {
    height: calc(100vh - 60px);
    // padding-right: 0.4rem;
    border-right: 1px solid $gray-color;
    // position: sticky;
    // top: 55px;
    overflow-y: auto;
}

.content-area {
    flex-grow: 1;
    margin-left: 0.4rem;
    width: 100%;
}

.icon-small {
    height: 0.5em;
    width: 0.5em;
}

.icon-medium {
    height: 0.7em;
    width: 0.7em;
}

.text-underline {
    text-decoration: underline;
}

.bg-white {
    background: #fff;
}

.bg-primary {
    color: inherit;
}

.btn.btn-rounded {
    border-radius: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btn-block {
    width: 100%;
}

.btn.btn-gray {
    color: $gray-color-dark;
    border-color: $gray-color-dark;
    &:hover {
        border-color: $gray-color;
    }
}

.btn.btn-gray:active {
    background-color: #f0f7ff;
}

.hr-light {
    border-style: solid;
    border-color: $border-color;
    border-top: 0;
}

.blink {
    animation: blinker 1.2s step-start infinite;
}
  
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

// OVERRIDES COLOR START
.text-primary {
    color: var(--color-primary) !important;
}

.bg-primary {
    background-color: var(--color-primary) !important;
}

.bg-alternate-row {
    background-color: var(--color-alternate-row) !important;
}

.label.label-secondary {
    color: var(--color-primary);
}

.label.label-primary {
    background-color: var(--color-primary) !important;
}

.btn {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

a.btn {
    color: var(--color-primary);
}

.btn:focus, 
.btn:hover {
    border-color: var(--color-primary);
}

.btn.btn-link {
    color: var(--color-primary);
}

.btn.btn-link:focus, 
.btn.btn-link:hover, 
.btn.btn-link:active, 
.btn.btn-link.active {
    color: var(--color-primary);
}

.btn.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn.btn-primary:focus, 
.btn.btn-primary:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    opacity: 0.8;
}

.btn:active, .btn.active {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    opacity: 0.8;
}

.btn-primary.btn-noeffects {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn.btn-primary.btn-noeffects:focus, .btn.btn-primary.btn-noeffects:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    opacity: 0.9;
}

.loading::after {
    border-bottom-color: var(--color-primary);
    border-left-color: var(--color-primary);
}

.pagination .page-item.active a {
    background-color: var(--color-primary);
}

.form-checkbox input:checked + .form-icon, 
.form-radio input:checked + .form-icon, 
.form-switch input:checked + .form-icon {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.form-input:focus {
    box-shadow: 0 0 0 0.1rem transparent;
    border-color: var(--color-primary);
}
// OVERRIDES COLOR END

// overrides spectre.css
.navbar {
    height: 50px;
    padding: 0 $unit-2;
    background-color: var(--color-primary);
    // background-color: $primary-color;
}

.navbar-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 11;
}

.navbar-brand {
    color: $light-color;
    margin-left: 10px;
    &:hover {
        color: darken($light-color, 10);
    }
    &:visited {
        color: #fff;
    }
}

.modal-body {
    overflow-y: auto;
    padding: 0.8rem;
    position: relative;
}

.modal-header {
    color: #303742;
    padding: 0.8rem;
}

.modal-container {
    max-height: 85vh;
    @media (max-width: 800px) {
        padding: 0 0.2rem;
        max-height: 95vh;
    }
    .modal-body {
        @media (max-width: 800px) {
            padding: 0.2rem;
        }
    }
}

.modal {
    @media (max-width: 800px) {
        align-items: flex-start;
        justify-content: right;
        padding: 1rem 0 0;
    }

    &.active {
        .modal-overlay {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    .modal-container {
        max-width: 800px;

        @media (max-width: 800px) {
            max-width: 100vw;
        }
    }

    &.modal-wide {
        .modal-container {
            max-width: 1048px;

            @media (min-width: $control-width-xl) {
                max-width: 1184px;
            }

            .modal-body {
                max-height: 80vh;
            }
        }
    }

    &.modal-ultrawide {
        .modal-container {
            max-width: 90%;

            .modal-body {
                max-height: 80vh;
            }
        }
    }
}

.column {
    @media (max-width: 600px) {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

#article-detail-modal {
    height: 80vh;
}

.form-icon[type='button'] {
    -webkit-appearance: inherit;
}

.form-group.form-group-dropdown {
    .form-icon {
        top: 0.7rem;
    }
}

.btn-article-detail-section {
    text-transform: uppercase;
}

.btn.btn-article-detail-section.disabled {
    background-color: #eee;
    color: var(--color-primary);
}

.form-switch.form-switch-inline {
    display: inline-block;
    left: 5px;
    top: 8px;

    &.form-switch-slim {
        top: 0;
        margin: 0;
        min-height: 1rem;
    }
}

.form-checkbox.form-checkbox-inline {
    display: inline-block;
    top: 4px;
    margin: 0 0 0 5px;
}

.form-input.input-md {
    height: 1.6rem;
}

.form-input.full-text-search-input {
    border-color: var(--color-primary);
}

// tree
.infinite-tree-scroll {
    max-height: 300px;
}

.infinite-tree-toggler {
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
    top: -7px;
}

.infinite-tree-leaf {
    display: inline-block;
    overflow: hidden;
    width: calc(90% - 18px);
    height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// navbar custom
// .navbar-search-section {
//     min-width: calc(100vw - 60px);
// }

// @media (min-width: 1024px) {
//     .navbar-search-section {
//         min-width: calc(100vw - 560px);
//     }
// }

// @media (min-width: 1400px) {
//     .navbar-search-section {
//         min-width: calc(100vw - 790px);
//     }
// }

.dropdown-nav {
    // padding: 5px 0;
    &>.btn {
        height: 50px;
        padding-top: 15px;
        border: 0 none;
        box-shadow: none;
    }

    &>.menu {
        transform: translateY(0);
    }
}

.navbar .navbar-brand {
    font-size: 1.1rem;
    font-weight: 400;
}

// highlights
.search-highlight {
    font-weight: 700;
    color: var(--color-primary);
}

strong.highlight {
    color: var(--color-primary);
}

// accordion
.accordion__title {
    outline: none;
    display: inline-block;
    cursor: pointer;
}

.accordion-article-title {
    // background: var(--color-primary)-light;
    background: $gray-color-dark;
    color: #fff;
    text-align: center;
    height: 40px;
    padding: 4px 0;
    // padding-top: 2px;

    &.--has-filters {
        height: 64px;
        font-size: 0.7rem;

        @media (min-width: 760px) {
            height: 40px;
            font-size: inherit;
        }
    }

    .form-checkbox,
    .form-switch {
        margin: 0;
        min-height: 1rem;
    }
}

.accordion-article-body {
    // max-height: 400px;
    overflow-y: auto;
}

.accordion-title-open .icon-arrow-right {
    transform: rotate(90deg);
    transition: all 0.2s ease;
}

.accordion-title-closed .icon-arrow-right {
    transform: rotate(0deg);
    transition: all 0.2s ease;
}

// Loading-wrapper
.loading-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 100%;
}

// Table
$expanded-row-border: 2px solid $gray-color;

.table {
    border-collapse: separate;

    @media (max-width: 800px) {
        overflow: auto;
        // display: block;
    }

    th {
        padding: 0.1rem 0.4rem;
        color: var(--color-primary);
        font-size: 0.9em;
        background-color: lighten($gray-color-dark, 40%);
        text-transform: uppercase;
    }
}

.table-dense {
    td {
        padding: 0.1rem 0.4rem;
    }
}

.table-superdense {
    td {
        padding: 0rem 0.4rem;
    }
}

.table-striped.table-striped-alt tbody tr:nth-of-type(odd) {
    background: lighten(#0095d0, 55%);
}

.table.table-striped tbody tr:nth-of-type(even) {
    background: #fff;
}

// .table-header-sortable {
//     cursor: pointer;
// }

.table-filters-container {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding-top: 0.8rem;
}

.table-img-preview {
    height: 140px;
    width: 120px;
    display: flex;
    align-items: center;
}

.table-cell-grid {
    &>div:first-child {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }

    &>div:last-child {
        border-right: 1px solid $border-color;
    }
}

.table-cell-brand {
    width: 155px;
}

.table-cell-code {
    width: 285px;
}

.table-cell-price {
    width: 150px;
}

.table-price-lg {
    font-size: 1.4em;
    color: $error-color;
}

.table-date-sm {
    font-size: 0.8em;
}

.table-article-recap {
    @extend .table-superdense;
    padding: 2px;

    &>tbody>tr>td:nth-child(1) {
        text-transform: capitalize;
        width: 50%;
        text-align: right;
        @media (min-width: 600px) {
            width: 220px;
        }
    }
}

.table-striped.table-striped-alt tbody tr:nth-of-type(odd) {
    background-color: var(--color-alternate-row);
}

.table-article-info {
    @extend .table-superdense;

    &>tbody>tr>td:nth-child(1) {
        width: 300px;
        text-align: right;
        @media (max-width: 800px) {
            width: 50%;
        }
    }

    &>tbody>tr>td:nth-child(2) {
        word-break: break-all;
    }

    &.table-articolo-info-multiple {
        margin-bottom: 40px;

        &>tbody>tr:nth-child(1) {
            background-color: var(--color-primary);
            color: #fff;
        }
    }
}

.table-button {
    display: inline-block;
    color: $gray-color;
    cursor: pointer;
    top: 3px;
    margin-right: 3px;

    &:hover {
        color: var(--color-primary);
    }

    &.table-button-primary {
        color: var(--color-primary);
    }
}

.table-users {
    display: block;

    @media (min-width: 1024px) {
        display: table;
    }
}

.article-row-details {
    padding: 0;
    border: 0 none;

    &>td {
        padding: 0;
        border: 0 none;
    }

    &.open {
        &>td {
            border: $expanded-row-border;
            border-top: none;
        }
    }
}

.article-row-expanded {
    position: relative;

    &>td {
        border-top: $expanded-row-border;
    }

    &>td:first-child {
        border-left: $expanded-row-border;
    }

    &>td:last-child {
        border-right: $expanded-row-border;
        position: relative;
    }
}

.article-row-details-content {
    overflow: hidden;
    height: 0;

    &.open {
        padding: 10px;
        overflow: auto;
    }
}

.article-row-details-close {
    position: absolute;
    height: 30px;
    width: 30px;
    top: -30px;
    right: -2px;
    border: $expanded-row-border;
    border-bottom: none;
}

.article-data-item {
    display: block;
    float: left;
    margin: 10px 10px 0 0;
    padding: 5px;
    background-color: #eee;
    width: calc(50% - 10px);
    height: 256px;
    border-radius: 5px;

    @media (min-width: 768px) {
        width: calc(33% - 10px);
    }

    @media (min-width: 1200px) {
        width: calc(20% - 10px);
    }

    // img {
    //     width: 100%;
    // }
}

.article-category-tab-btn {
    width: 100%;
    display: inline-block;
    margin: 0 0.5% 0.2rem 0;
    @media (min-width: 616px) {
        width: 49.5%;
    }

    @media (min-width: 768px) {
        width: 32.5%;
    }

    @media (min-width: 1200px) {
        width: 24.5%;
    }
}

.article-ftp-button-holder {
    @apply relative lg:absolute right-0 lg:right-2 bottom-1 lg:bottom-2;
}

.item-data-categories-element {
    margin-bottom: 1em;
}

.item-data-category-list-wrapper {
    margin: 10px 20px;
    padding: 10px 20px;
    border: 1px solid $border-color;
    border-radius: 20px;
}

.gallery-modal-controls {
    margin-right: 60px;

    .icon {
        margin-top: 0.4rem;
    }

    .divider-vert {
        height: 2.4rem;

        &::before {
            top: 0.1rem;
            bottom: 0.1rem;
        }
    }
}

// .article-block-container {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     padding-top: .5%;
//     width: 100%;
// }

// .article-block-item {
//     width: 24%;
//     margin: 0.5%;
//     border-radius: 5px;
// }

// .card-image-wrapper {
//     padding: 0.5rem;
//     // padding-bottom: 0;
//     display: flex;
//     .card-image {
//         flex-grow: 1;
//         // border-bottom: 1px solid $border-color;
//     }
//     .card-image-side {
//         width: 100px;
//         flex-grow: 0;
//         // border-bottom: 1px solid $border-color;
//         padding-left: 5px;
//     }
// }

.card-header {
    border-top: 1px solid $border-color;
}

// .card-header {
//     min-height: 95px;
// }

.card-body-wrapper {
    height: 190px;
    border-bottom: 1px solid $border-color;
}

// Pagination
.pagination.pagination-right {
    justify-content: flex-end;
}

.pagination.pagination-compact {
    margin: 0;
    padding: 0;
}

.pagination .page-item a {
    color: $gray-color-dark;
}

// Forms
.form-compact {
    .form-group {
        margin-bottom: 0;
    }

    .form-checkbox {
        margin: 0;
    }
}

.form-input.form-number {
    padding-right: 0.4rem;
}

.form-input-hint {
    margin-bottom: 0;
}

// App loader
.splash-loader {
    margin-top: 100px;
}

// Texts
.text-label {
    color: var(--color-primary);
}

.text-small {
    font-size: 0.9em;
}

.rc-slider-tooltip {
    z-index: 10;
}

.img-thumbnail {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px; // line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: all .2s ease-in-out;
}

.img-responsive {
    max-height: 100%;
}

.m-a {
    margin: 0 auto;
}

// Print
// .table tr td,
// .table tr th {
//     page-break-inside: avoid;
//     // white-space: nowrap;
// }
// .table tr td:before,
// .table tr td:after {
//     content: "";
//     height: 4px;
//     display: block;
// }
.accordion-article-title {
    page-break-inside: avoid;
    break-after: avoid;
}

// .empty {
//     page-break-inside: avoid;
// }
.article-details-img-wrapper {
    @apply min-h-[220px] lg:min-h-[40px];
    display: flex;
    align-items: center;
    height: calc(100% - 50px);
    // min-height: 40px;
}

// Login form
.form-spaced {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    @media (min-width: 1200px) {
        padding: 30px 40px 40px;
    }
}

.login-wrapper {
    top: 0;
    left: 0;
    min-height: 100vh;
    padding-top: 10px;
    @media (min-width: 1200px) {
        padding-top: 60px;
    }
}

// Swal2
.swal2-popup {
    font-size: 0.95rem !important;
}

// Toasts
.Toastify__toast {
    border: .05rem solid #454d5d;
    border-color: #454d5d;
    border-radius: .1rem;
}

.Toastify__toast-body {
    color: #fff;
    display: block;
    padding: .4rem;
    width: 100%;
}

.Toastify__toast.Toastify__toast--success {
    // background: rgba(50, 182, 67, .9);
    // border-color: #32b643;
    @extend .toast, .toast-success
}

.Toastify__toast.Toastify__toast--error {
    // background: rgba(232, 86, 0, .9);
    // border-color: #e85600;
    @extend .toast, .toast-error
}

.Toastify__toast.Toastify__toast--warning {
    // background: rgba(255, 183, 0, .9);
    // border-color: #ffb700;
    @extend .toast, .toast-warning
}

.Toastify__toast.Toastify__toast--info {
    // background: rgba(87, 85, 217, .9);
    // border-color: #5755d9;
    @extend .toast, .toast-primary
}

.checkmark-container {
    display: block;
    position: relative;
    padding-left: 25px;
    padding-top: 8px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
    background-color: var(--color-primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.rc-tooltip-error {
    display: none;
    @media (min-width: 768px) {
        display: block;
        &.rc-tooltip-hidden {
            display: none;
        }
    }
}

// Tabs

.TabsRoot {
    display: flex;
    flex-direction: column;
    // width: 300px;
    // box-shadow: 0 2px 10px var(--blackA4);
}

.TabsRoot[data-orientation="vertical"] {
    flex-direction: column;
    min-height: 480px;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    .TabsList {
        flex-direction: column;
        width: 100%;
        border-right: 1px solid #ccc;
        border-bottom: none;
        @media (min-width: 768px) {
            width: 340px;
        }
    }

    .TabsTrigger {
        justify-content: flex-start;
        flex-grow: 0;
        min-height: 45px;
        border-bottom: 1px solid #ccc;
        // height: 60px;

        // &.TabsTrigger-enabled {
        //     // color: var(--color-primary);
        //     // font-weight: bold;
        //   }
    }

    .TabsTrigger:first-child {
        border-top-left-radius: 0;
    }

    .TabsTrigger:last-child {
        border-top-right-radius: 0;
    }

    .TabsTrigger[data-state='active'] {
        background-color: var(--color-alternate-row);
        box-shadow: none;
    }
}
  
.TabsList {
    flex-shrink: 0;
    display: flex;
    border-bottom: 1px solid #ccc;
}
  
.TabsTrigger {
    font-family: inherit;
    background-color: white;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1;
    color: var(--mauve11);
    user-select: none;
    flex-grow: 0;
}

.TabsTrigger:first-child {
    border-top-left-radius: 6px;
}

.TabsTrigger:last-child {
    border-top-right-radius: 6px;
}

.TabsTrigger:hover {
    color: var(--violet11);
}

.TabsTrigger[data-state='active'] {
    // background-color: var(--color-alternate-row);
    box-shadow: inset 0 0 0 0 var(--color-primary), 0 2px 0 0 var(--color-primary);
}

.TabsTrigger:focus {
    position: relative;
    // box-shadow: 0 0 0 2px black;
}
  
.TabsContent {
    flex-grow: 1;
    padding: 10px;
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    outline: none;
}

.export-type-btn {
    @apply text-center border py-3 cursor-pointer hover:font-bold text-primary;

    &.export-type-current {
        @apply font-bold border-primary bg-primary;
        color: white !important;
    }

    &.export-type-disabled {
        background-color: #ddd;
        color: #bbb;
        cursor: not-allowed;
    }
}
//   .TabsContent:focus {
//     box-shadow: 0 0 0 2px black;
//   }

// Popper Arrow

// .popper-arrow {
//     position: absolute;
//       width: 3em;
//       height: 3em;
//       &[data-placement*='bottom'] {
//         top: 0;
//         left: 0;
//         margin-top: -0.9em;
//         width: 3em;
//         height: 1em;
//         &::before {
//           border-width: 0 1.5em 1em 1.5em;
//           border-color: transparent transparent #232323 transparent;
//         }
//       }
//       &[data-placement*='top'] {
//         bottom: 0;
//         left: 0;
//         margin-bottom: -0.9em;
//         width: 3em;
//         height: 1em;
//         &::before {
//           border-width: 1em 1.5em 0 1.5em;
//           border-color: #232323 transparent transparent transparent;
//         }
//       }
//       &[data-placement*='right'] {
//         left: 0;
//         margin-left: -0.9em;
//         height: 3em;
//         width: 1em;
//         &::before {
//           border-width: 1.5em 1em 1.5em 0;
//           border-color: transparent #232323 transparent transparent;
//         }
//       }
//       &[data-placement*='left'] {
//         right: 0;
//         margin-right: -0.9em;
//         height: 3em;
//         width: 1em;
//         &::before {
//           border-width: 1.5em 0 1.5em 1em;
//           border-color: transparent transparent transparent#232323;
//         }
//       }
//       &::before {
//         content: '';
//         margin: auto;
//         display: block;
//         width: 0;
//         height: 0;
//         border-style: solid;
//       }
// }